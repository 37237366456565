* {
	box-sizing: border-box;
}
.table {
	width: 100%;
	border-collapse: collapse;
}

.table td,
.table th {
	padding: 12px 15px;
	border: 1px solid #ddd;
	text-align: center;
	font-size: 16px;
}

.table th {
	background-color: rgb(109, 113, 111);
	color: rgb(0, 0, 0);
}

.table tbody tr:nth-child(even) {
	background-color: #f5f5f5;
}
.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%; /* Optional: Set a height if needed */
}
@media (max-width: 500px) {
	/* .table thead {
		display: none;
	} */

	.table,
	.table tbody,
	.table tr,
	.table td {
		/* display: block; */
		width: 100%;
	}
	.table tr {
		margin-bottom: 15px;
	}
	/* .table td {
		text-align: right;
		padding-left: 50%;
		text-align: right;
		position: relative;
	} */
	.table td::before {
		content: attr(data-label);
		position: absolute;
		left: 0;
		width: 50%;
		padding-left: 15px;
		font-size: 15px;
		font-weight: bold;
		text-align: left;
	}
}
