.hidden-xs {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
  
  @media (min-width: 991.98px) {
    .spardha-logo {
      width: 40% !important;
    }
  }
  
  .circle-logo {
    margin: 0 auto;
    max-width: 30px !important;
    text-align: center;
  }
  
  .logo_db {
    text-align: center;
  }
  
  .logo_db img {
    max-width: 180px;
    padding: 16px 0 17px;
    width: 100%;
  }
  
  @media screen and (max-width: 820px) {
    .logo_db img {
      max-width: 100px;
    }
  }
  
  .navi .item_nav {
  
  display: flex !important;
  justify-content: center;
    display: block;
    font:Poppins;
    font-size: 17px;
    font-weight: 500;
   
    text-decoration: none;
  }
  
  .navi i {
    margin-right: 15px;
    color: #4D44B5;
  }
  
  .navi .active .item_nav {
    background: white;
    color: #4D44B5;
    border-left: 5px solid #4D44B5;
    padding-left: 15px;
  
  }
  
  .navi .item_nav:hover {
  
   
   color: #4D44B5;
    display: block;
  
  }
  
  #navigation {
    /* position: fixed; */
    background: #4D44B5;
  }
  
  #navigation {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  @media only screen and (max-width: 767px) {
    #navigation .navi .item_nav {
      font-size: 14px;
      padding: 20px;
      text-align: center;
    }
  
    #navigation .navi i {
      margin-right: 0px;
    }
  
    #navigation .navi .item_nav:hover,
    #navigation .navi .active .item_nav {
      background: white;
      color: #4D44B5;
      border-left: none;
      display: block;
      padding-left: 20px;
    }
  }
  
  @media only screen and (min-width: 998px) and (max-width: 1350px) {
    #navigation .logo_db img {
      max-width: 130px;
      padding: 16px 0 17px;
      width: 100%;
    }
  }
  
  #navigation svg {
    /* color: white; */
    margin-right: 7px;
  }
  .sidebar-header{
    background: #4D44B5;
  }
  .side-menu{
    background: #4D44B5;
  }
  .nav-link:hover{
    background-color: white;
    color: #4D44B5 !important;
  }
  .nav-link svg{
    color: white;
  }
  .nav-link:hover svg{
    color: #4D44B5;
  }
  .nav-link{
    color: white !important;
    width: 80%;
    height: fit-content;
    border-radius: 10px;
    font-size: 14px !important;
  }
  