
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
}

.app {
  display: flex;
  background-color: #f8f8f8;
  height: 100%;
}


.content{
  width:100vw;
  padding: 10px;
  overflow: auto;
}

/* .image-container {
  text-align: center;
  margin-bottom: 20px;
}

.image-container img {
  max-width: 60vw;
  height: 35vh;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
} */


.sidebar1 {
  /* width: 100vw; */
  background-color: #292b2c;
  color: white;
  font-weight: bold;
  padding: 30px;
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.1);
  overflow-y:scroll;
  min-width:200px;
}

.sidebar-icon {
  cursor: pointer;
  margin-bottom: 10px;
  padding:15px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.sidebar-icon:hover {
  background-color: #373a3c;
}

.match-list {
  width: 100%;
  flex: 1;
  padding: 30px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  
}
.match-item {
  /* margin-bottom: 20px; */
  padding: 20px;
  border-bottom: 1px solid #ddd;

}

.match-teams {
  flex:1;
  flex-grow: 2.0;
  font-size: 1.2rem;
  font-weight: bold;
  width: max-content;
  color: #333;
  align-items: center;
  justify-content: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.match-details{
  display: flex;
  align-items: center;
  justify-content: space-around;
  
}

.match-info {
  flex:2;
  flex-grow: 2;
  color: #777;
  display: flex;
  align-items: center;
  justify-content: center;
}

.match-time {
  flex:3;
  flex-grow: 2;
  color: #007bff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

}
.match-date{
  flex: 4;
  flex-grow: 2;
  color: #013c7a;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.match-details div div{
  padding: 5px;
}


.view-details {
  flex:5;
  flex-grow: 2;
  display: flex;
  align-items: center;
}



.view-details button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.details-text {
  color: #777;
  margin-left: 10px;
  font-style: italic;
}

.view-details button:hover {
  background-color: #0056b3; 
}

.details-text {
  color: #777;
  margin-top: 5px;
  font-style: italic;
  transition: opacity 3s ease-in-out; 
}

.details-text.visible {
  opacity: 1;
}

.details-text.hidden {
  opacity: 0;
}
.heading{
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  border: 2px solid #333;
  padding: 10px;
  margin: 20px auto;
  width: 70%;
  border-radius: 8px;
  background-color: #f0f0f0; /* Adjust background color as needed */
}
.fixbody{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.vr{
  border-left: 2px solid #0056b3;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .app {
    flex-direction: column;
    width: 100vw;
  }

  .sidebar {
    width: 100vw;
    margin-bottom: 20px;
  }

  .match-list {
    padding: 20px;
    width: 100vw;
  }
}
.sidebar-icon.selected {
  background-color: #373a3c;
}
  

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fixtureForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 55px;
}
.submitBtn{
  margin-top: 10px;
  border-radius: 20px;
  background-color: #4D44B5;
}
.submitBtn:hover{
  background-color: #4D44B5;
  color: white;
}
.label {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
h1{
  color: #4D44B5;
  text-align: center;
}