.card {
    display: inline-block;
    background-color: rgb(222, 221, 221);
    margin: 10px;
    padding:15px;
    border-radius: 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.fieldName{
    font-weight: bold;
    font-size: 20px;
    margin-right: 10px;
}

body{
    background-color: rgb(217, 237, 255);
}

.fields {
    margin-right: 30px;
}
button {
	color: white;
	font-size: 1.5rem;
	margin-left: 1rem;
	cursor: pointer;

    padding:20px;
    background-color: #0bad41;
    transition: all 500ms;
    border: none;
    border-radius: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
button:hover {
	background-color: #0cda62;
    color:black;
    scale:1.1;
}
button:disabled{
    background-color: grey;
    color: white;
    cursor: not-allowed;
}

.card-container{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}

p{
    font-size: 1.6rem;
    text-align: center;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}






table{
    width:100vw;
    border-collapse: collapse;
}
td{
    padding:5px;
    text-align: center;
    border:1px solid grey;
    margin:0;
}
th{
    border:2px solid grey;
}

.error-message {
  background-color: #ffdddd;
  border: 1px solid #ff0000;
  color: #ff0000;
  padding: 10px;
  margin: 10px 0;
}