.maindiv{
    height: 100vh;
    margin: 0px;
}





.Db {
    display: flex;
    width: 100%;
    align-items: stretch;
    font-family: 'Poppins', sans-serif;
    background: #D9D9D9;
    /* overflow-y: scroll; */
  }
  
  @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; 
  
  /* body {
  }
  
  */
  /* a,
  a:hover,
  a:focus {
      color: inherit;
      text-decoration: none;
      transition: all 0.3s;
  }
  
  */
  
  /* ---------------------------------------------------
        SIDEBAR STYLE
    ----------------------------------------------------- */
  .sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #4D44B5;
    color: #fff;
    /* margin-left: -250px; */
    transition: all 0.5s;
    height: 100%;
  }
  
  .sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
  }
  
  .sidebar-header {
    background: rgba(118, 14, 83, 1);
  }
  
  .sidebar-header h3 {
    color: #fff;
    padding: 1em;
  }
  
  .sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  .menu-open {
    background: #760E53;
  }
  
  /* .nav-item:hover {
      color: darkgreen;
      background: #fff;
  }
  
  */
  
  .items-menu {
    color: #fff;
    background: #760E53;
  }
  
  /* ADD IF REQUIRED */
  /* li a.dropdown-toggle::after {
      display: inline-flex;
      position: relative;
      left: 30%;
      top: 10%;
  }
  
  */
  
  .sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
  
  .side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
  }
  
  .side-menu::-webkit-scrollbar {
    width: 10px;
  }
  
  .side-menu::-webkit-scrollbar-thumb {
    background: #760E53;
    border-radius: 3px;
  }
  
  .side-menu::-webkit-scrollbar-thumb:hover {
    background:#760E53;
  }
  
  .maindiv{
    display: flex;
}