
a{
    text-decoration: none;
    color: white;
}

.navbar{
    /* background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 20px; */
    display: inline-flex;
padding: 9px 33px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 35px;
/* border: 2px solid #4D44B5; */
margin: 3px;

color: var(--main-blue, #4D44B5);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
    
}