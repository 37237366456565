@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@800&display=swap");

.loginDiv {
  margin: 120px 200px 0;
  border-radius: 20px;
  border: 1px solid var(--main-blue, #4d44b5);
  background: #fff;
}

.heading {
  color: var(--main-blue, #4d44b5);
  font-family: Poppins, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  /*color: #000000;
  font-family: "League Spartan";
  font-size: 40px;
  font-weight: bold; */
}

.form-horizontal {
  text-align: center;
}

.form-horizontal .form-group {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
  box-sizing: border-box;
}

.form-group {
  /* margin-bottom: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000000; */
  color: var(--black-theme, #04103b);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.footer {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}
.btn-block {
  color: var(--main-blue, #4d44b5) ;
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 42px;
  padding: 11px 60px;
  background-color: #fff ;
  border: 1px solid #4d44b5;
  display: flex;
  justify-content: center;
  filter: none;
  transition: 300ms;
  padding-right: 15px;
}
.offHover{
  margin-left: 10px;
}

.btn-block:hover{
  scale: none;
  color: white;
  background-color: #4d44b5;
}
.btn-block:focus {
  box-shadow: none !important;
}

.panel {
  box-sizing: border-box;
  /* background-color: #ffffff; */
  margin-bottom: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 4px;

  /* -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}

.panel-heading {
  /* background-color: #f2f2f2 !important; */
  background: var(--main-blue, #4d44b5);
  font-family: Poppins, sans-serif;
  padding: 10px 15px;
  display: flex;
  justify-content: center;

  color: #ffffff;
  /* background-color: #f2f2f2 !important; */
}

.panel-title {
  color: #fff;
  font-family: Poppins,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: grid;
  align-content: center;
}

/* .panel-title:hover {
  text-decoration: underline;
} */

.label {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: left;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0px !important;
  font-weight: 700;
  font-size: 14px;
  width: 50%;
}

.form-control {
  height: 34px;
  /* font-size: 14px !important; */
  color: #555;
  /* border-left: none !important; */
  color: var(--main-blue, #4d44b5);
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid var(--main-blue, #4d44b5);
  width: 50%;
  margin-top: 10px;
  padding-left: 15px;
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  box-shadow: none !important;
  border-color: dodgerblue !important;
  box-shadow: 3px 1px 8px rgba(102, 175, 233, 0.6) !important;
}

.form-text {
  font-size: 1.5em !important;
  line-height: 0.75em !important;
  height: 34px;
  padding-left: 0.4rem !important;
  padding-right: 0.1rem !important;
  background-color: white !important;
  border-right: none !important;
  color: #aaa;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group:focus-within .form-text {
  color: dodgerblue !important;
  border-color: dodgerblue !important;
  box-shadow: 1px 1px 8px rgba(102, 175, 233, 0.6);
  background-color: white;
  transition: none !important;
}
@media (max-width: 830px) {
  .loginDiv{
    margin: 100px 100px 32px 100px;
  }
  .form-control{
    width: 60%;
  }
}
@media (max-width: 540px) {
  .loginDiv{
    margin: 100px 50px 32px 50px;
  }
}
@media (max-width: 450px) {
  .loginDiv{
    margin: 100px 30px 32px 30px;
  }
  .btn-block{
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 395px) {
  .loginDiv{
    margin: 100px 11px 32px 11px;
  }
}