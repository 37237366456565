/* styles.module.css */
.teamTableContainer {
  margin: 20px;
}

.teamTable {
  width: 100%;
  border-collapse: collapse;
}

.teamTable th, .teamTable td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}
.teamTable th {
	background-color: rgb(109, 113, 111);
	color: rgb(0, 0, 0);
}
.noGames {
  margin: 20px;
  font-size: 18px;
  color: #555;
}
